'use strict';

import crypto from 'crypto';

const STRINGS = '0123456789acdefghikmnpqrstuvwxyzABDEFGHIJKLMNOPQRSTUVWXYZ';
const URL_PATTERN = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))' + // domain or ip
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query
    '(\\#[-a-z\\d_]*)?$',
  'i'
); // locator
const ABSOLUTE_PATH_PATTERN = /[/]|[^/.:]+\.[^/.:]+/;

/**
 *
 * @param {*} length
 */
export function createPIN(length = 4) {
  var password = '';
  for (var i = 0; i < length; i++) password += Math.floor(Math.random() * 10);
  return password;
}

/**
 * パスワード生成
 * @param {Number} length
 */
export function createPassword(length = 8) {
  var password = '';
  for (var i = 0; i < length; i++)
    password += STRINGS.charAt(Math.floor(Math.random() * STRINGS.length));
  getHash(password);
  return password;
}

export function getHash(value) {
  if (!value) return null;
  let algorithm = crypto.createHash('sha512');
  algorithm.update(value);
  return algorithm.digest('hex');
}

/**
 * オブジェクトをJsonに変換する
 * @param {Object} Object
 */
export function toJson(obj) {
  return JSON.stringify(obj);
}

/**
 * 小文字英字
 * @param {String} value
 */
export function isLowerAlphabetic(value) {
  return checkCharType(value, CharType.LowerAlphabetic);
}
/**
 * 大文字英字
 * @param {String} value
 */
export function isUpperAlphabetic(value) {
  return checkCharType(value, CharType.UpperAlphabetic);
}
/**
 * 英字
 * @param {String} value
 */
export function isAlphabetic(value) {
  return checkCharType(value, CharType.Alphabetic);
}
/**
 * 整数
 * @param {String} value
 */
export function isNumeric(value) {
  return checkCharType(value, CharType.Numeric);
}
/**
 * 英数字
 * @param {String} value
 */
export function isAlphanumeric(value) {
  return checkCharType(value, CharType.Alphanumeric);
}
/**
 * 全角カタカナ
 * @param {String} value
 */
export function isKana(value) {
  return checkCharType(value, CharType.Kana);
}
/**
 * 全角ひらがな
 * @param {String} value
 */
export function isHiragana(value) {
  return checkCharType(value, CharType.Hiragana);
}
/**
 * マルチバイト文字
 * @param {String} value
 */
export function isMultiByte(value) {
  return checkCharType(value, CharType.MultiByte);
}

/**
 * パスワード判定
 * @param {String} value
 */
export function isPassword(value) {
  return checkCharType(value, CharType.Password);
}

/**
 * 文字判定
 * @param {String} value
 * @param {RegExp} pattern
 * @returns is Match
 */
function checkCharType(value, pattern) {
  return value.match(pattern) ? true : false;
}
/**
 * check value is valid URL or not
 * @param {String} value
 * @returns is Match
 */
export function isURL(value) {
  return checkCharType(value, URL_PATTERN);
}

/**
 * check value can be estimate as absolute path or not
 * @param {String} value
 * @returns is Match
 */
export function isAbsolutePath(value) {
  return checkCharType(value, ABSOLUTE_PATH_PATTERN);
}

/**
 * check value can be estimate as URL or not
 * @param {String} value
 * @returns is Match
 */
export function likeURL(value) {
  return isURL(value) || isAbsolutePath(value);
}

/* eslint-disable */
const CharType = {
  LowerAlphabetic: /^[a-z]+$/,
  UpperAlphabetic: /^[A-Z]+$/,
  Alphabetic: /^[a-zA-Z]+$/,
  Numeric: /^[0-9]+$/,
  Alphanumeric: /^[0-9a-zA-Z]+$/,
  Kana: /^[\u30a1-\u30f6]+$/,
  Hiragana: /^[\u3041-\u3096]+$/,
  MultiByte: /^[^\x01-\x7E\xA1-\xDF]+$/, //eslint-d
  Password: /^[0-9a-zA-Z!#$%&@.*\-_]+$/
};

/**
 * 2点の地球座標から距離を求める
 * @param {float} lat1
 * @param {float} lon1
 * @param {float} lat2
 * @param {float} lon2
 * @returns 距離(m)
 */
export function getDistance(lat1, lon1, lat2, lon2) {
  const deg2rad = d => d * (Math.PI / 180);

  // 緯度経度をラジアンに変換
  let radLat1 = deg2rad(lat1);
  let radLon1 = deg2rad(lon1);
  let radLat2 = deg2rad(lat2);
  let radLon2 = deg2rad(lon2);

  // 緯度差
  let radLatDiff = radLat1 - radLat2;
  // 経度差算
  let radLonDiff = radLon1 - radLon2;
  // 平均緯度
  let radLatAve = (radLat1 + radLat2) / 2.0;

  // 測地系による値の違い
  const Radius = 6377397.155; // 赤道半径
  const E2 = 0.00667436061028297; // 第一離心率^2
  const Meridian = 6334832.10663254; // 赤道上の子午線曲率半径

  let sinLat = Math.sin(radLatAve);
  let W2 = 1.0 - E2 * (sinLat * sinLat);
  let M = Meridian / (Math.sqrt(W2) * W2); // 子午線曲率半径M
  let N = Radius / Math.sqrt(W2); // 卯酉線曲率半径

  let t1 = M * radLatDiff;
  let t2 = N * Math.cos(radLatAve) * radLonDiff;
  let distance = Math.sqrt(t1 * t1 + t2 * t2);

  return distance;
}

/**
 * 2点座標が範囲内の距離であることを確認する
 * @param {float} lat1
 * @param {float} lon1
 * @param {float} lat2
 * @param {float} lon2
 * @param {Number} range
 */
export function inRange(lat1, lon1, lat2, lon2, range) {
  let distance = getDistance(lat1, lon1, lat2, lon2);
  return distance < range;
}

/**
 * 2 digit zero padding
 * note: hard coded for processing speed for each digit
 * @param {Number} num
 */
export function zeropad2(num) {
  return ('00' + num).slice(-2);
}

/**
 * 3 digit zero padding
 * note: hard coded for processing speed for each digit
 * @param {Number} num
 */
export function zeropad3(num) {
  return ('000' + num).slice(-3);
}

/**
 * 1 digit decimal places formatting
 * note: hard coded for processing speed for each digit
 * @param {Number} num
 */
export function formatDecimal1(num) {
  return Math.floor(num * 10) / 10;
}

/**
 * 2 digit decimal places formatting
 * note: hard coded for processing speed for each digit
 * @param {Number} num
 */
export function formatDecimal2(num) {
  return Math.floor(num * 100) / 100;
}

/**
 * 3 digit decimal places formatting
 * note: hard coded for processing speed for each digit
 * @param {Number} num
 */
export function formatDecimal3(num) {
  return Math.floor(num * 1000) / 1000;
}

export const hsv2rgb = {};

/**
 * translate hsv to rgb array
 * @param {Number} h Hue: 0-1
 * @param {Number} s Saturation: 0-1
 * @param {Number*} v Brightness: 0-1
 */
hsv2rgb.array = function(h, s, v) {
  h = Math.max(Math.min(h, 1), 0);
  s = Math.max(Math.min(s, 1), 0);
  v = Math.max(Math.min(v, 1), 0);

  let r, g, b;
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      (r = v), (g = t), (b = p);
      break;
    case 1:
      (r = q), (g = v), (b = p);
      break;
    case 2:
      (r = p), (g = v), (b = t);
      break;
    case 3:
      (r = p), (g = q), (b = v);
      break;
    case 4:
      (r = t), (g = p), (b = v);
      break;
    case 5:
      (r = v), (g = p), (b = q);
      break;
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

hsv2rgb.hex = function(h, s, v) {
  const rgb = hsv2rgb.array(h, s, v);
  return (
    '#' +
    rgb
      .map(function(value) {
        return zeropad2(value.toString(16));
      })
      .join('')
  );
};
