'use strict';

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAnalytics from 'vue-analytics';
import Home from '../views/Home.vue';

import beforeEach from './beforeEach';

const AnalyticsID = 'G-JSH6CFL4YF';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      public: true
    }
  },
  {
    // リテラシーアプリ
    path: '/literacy',
    component: () => import('@/views/literacy/Home'),
    children: [
      {
        path: '',
        name: 'LiteracyMap',
        component: () => import('@/views/literacy/Map')
      },
      {
        path: '/literacy/room',
        name: 'LiteracyRoom',
        component: () => import('@/views/literacy/Game'),
        props: true
      },
      {
        path: '/literacy/debug',
        name: 'LiteracyRoomDebug',
        component: () => import('@/views/literacy/Debug'),
        props: true
      }
    ]
  },
  {
    // 情報能力活用アプリ
    path: '/ability',
    component: () => import('@/views/ability/Home'),
    children: [
      {
        path: '',
        name: 'AbilityMenu',
        component: () => import('@/views/ability/Menu')
      },
      {
        path: '/ability/landmark',
        name: 'Landmark',
        component: () => import('@/views/ability/Landmark'),
        props: true
      },
      {
        path: '/ability/question',
        name: 'Question',
        component: () => import('@/views/ability/Question'),
        props: true
      },
      {
        path: '/ability/result',
        name: 'Result',
        component: () => import('@/views/ability/Result'),
        props: true
      },
      {
        path: '/ability/ranking',
        name: 'Ranking',
        component: () => import('@/views/ability/Ranking'),
        props: true
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login'),
    meta: {
      public: true
    },
    props: true
  },
  {
    path: '/policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy'),
    meta: {
      public: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(beforeEach);

Vue.use(VueAnalytics, {
  id: AnalyticsID,
  router
});

export default router;
