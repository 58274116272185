'use strict';

import { API_SERVER_URL as BaseURL } from '@/defines';
import { getHash } from '@/service/utils';
import axios from 'axios';

function getClient() {
  return axios.create({
    baseURL: BaseURL + '/auth'
  });
}

export default {
  namespaced: true,
  state: {
    token: ''
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    destroy(state) {
      state.token = null;
    }
  },
  actions: {
    /**
     * 認証
     */
    async auth(context, payload) {
      let result;
      try {
        let client = getClient();
        payload.password = getHash(payload.password);
        result = await client.post('/', payload);
      } catch (e) {
        console.error(e.response);
        this.commit('logout');
        throw e;
      }

      //戻り値検証
      if (result.data.success) {
        let token = result.data.token;
        //トークンを設定
        context.commit('setToken', token);
        this.commit('login');
      } else {
        console.error(result.data);
        //トークンを破棄
        this.commit('logout');
      }
      return result.data;
    },
    /**
     * 検証
     */
    async verify(context) {
      let result;
      try {
        let client = getClient();
        result = await client.get('/' + context.state.token);
      } catch (e) {
        console.error(e.response);
        this.commit('logout');
        throw e;
      }

      if (result.data.success) {
        this.commit('login');
      } else {
        console.error(result.data);
        this.commit('logout');
      }

      return result.data;
    }
  }
};
