/* Store.modules.api */
'use strict';

import * as api from '../../service/api';
import { getHash } from '@/service/utils';

/**
 * Null 又は Undefined を判定する
 * @param {*} value
 * @returns {Boolean}
 */
function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export default {
  namespaced: true,
  state: {
    id: null,
    type: null
  },
  getters: {},
  mutations: {},
  actions: {
    async getApiVersion() {
      return await get('/');
    },
    async getLicense() {
      return await get('/license');
    },
    async getManagementVersion() {
      return (await api.httpGet('/management/version.json')).data;
    },
    async getMainVersion() {
      return (await api.httpGet('/version.json')).data;
    },
    //部屋一覧取得
    async getLiteracy(context, payload) {
      return await get(
        '/literacy',
        undefined,
        payload ? { upper: payload } : undefined
      );
    },
    //部屋情報取得
    async getRoom(context, payload) {
      return await get('/literacy/room', payload.id, {
        status: payload.status
      });
    },
    /**
     * 部屋のステータスを設定
     * @param {*} context
     * @param {*} payload
     * payload = {
     *  id : room_id,
     *  user_id : user_id,
     *  result : result,
     *  screen : 0|1|2
     * }
     */
    async setRoomUserData(context, payload) {
      return await post('/literacy/room/userdata', payload.data, payload.id);
    },
    /**
     * テストデータ集計
     * @param {*} context
     * @param {*} payload
     * payload = {
     *  roomId : room_id,
     *  questionId : 0~
     * }
     */
    async setTestData(context, payload) {
      let params = [payload.roomId, payload.questionId];
      return await post('/literacy/room', undefined, params);
    },
    /**
     * アンケート既読
     * @param {*} context
     * @param {*} payload アンケートID
     */
    async setEnquete(context, payload) {
      return await post('/literacy/enquete', undefined, payload);
    },
    async getImage(context, payload) {
      return window.URL.createObjectURL(await api.getImage(payload));
    },
    async getImageStream(context, payload) {
      return api.getImageStream(payload.url, payload.onRead);
    },
    async getAbility() {
      return await get('/ability');
    },
    /**
     * @param {*} payload no=0でAR
     * payload = {
     *  id : facilityId,
     *  no : questionNo
     * }
     */
    async getFacility(context, payload) {
      return await get('/ability', [payload.id, payload.no]);
    },
    /**
     * @param {*} payload no=0でAR
     * payload = {
     *  id   : facilityId,
     *  no   : questionNo,
     *  time : second //no=0の場合不要
     * }
     */
    async setFacilityUserData(context, payload) {
      let data = {};
      if (!isNullOrUndefined(payload.time)) data.time = payload.time;
      return await post('/ability', data, [payload.id, payload.no]);
    },
    async getAbilityRanking() {
      return await get('/ability/ranking');
    },
    /**
     * @param {*} context
     * @param {*} payload {login_id, passcode}
     */
    async checkPasscode(context, payload) {
      let params = [payload.login_id, payload.passcode];
      return await get('/user/password', params);
    },
    /**
     * @param {*} context
     * @param {*} payload { login_id, password, passcode }
     */
    async setPassword(context, payload) {
      payload.password = getHash(payload.password);
      return await post('/user/password', payload);
    },
    /**
     * エラーの記録
     * @param {*} payload
     * payload = {
     *  server  : default="client",
     *  message : エラーメッセージ
     *  stack   : スタックトレース
     * }
     */
    async setError(context, payload) {
      return await post('/error', payload);
    }
  }
};

///////////////
// Functions //
///////////////

/**
 * GET Method
 * @param {String} path
 * @param {String|String[]} params
 * @param {Object} query
 */
async function get(method, params, query) {
  return (await api.get(method, params, query)).data;
}
/**
 * POST Method
 * @param {String} path
 * @param {String|String[]} params
 * @param {Object} query
 */
async function post(method, data, params) {
  return (await api.post(method, data, params)).data;
}
/**
 * PUT Method
 * @param {String} path
 * @param {Object} data
 * @param {String|String[]} params
 */
// async function put(method, data, params) {
//   return (await api.put(Root + method, data, params)).data;
// }
/**
 * DELETE Method
 * @param {String} path
 * @param {Object} data
 * @param {String|String[]} params
 */
// async function del(method, params, query) {
//   return (await api.del(Root + method, params, query)).data;
// }
