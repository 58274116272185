const p = require('../../package.json');

export async function license2ScrollTextData(src) {
  const dst = [];

  const version = p.version;

  dst.push({ message: '<h1>Version</h1>' });
  dst.push({ message: `<ul><li>${version}</li></ul>` });

  dst.push({ message: '<h1>Licenses</h1>' });
  for (let category in src) {
    if (category === 'management') continue;
    const html = ['<h2>' + category.toUpperCase() + '</h2>'];
    const module = src[category];
    html.push('<ul>');
    for (name in module) {
      const license = module[name];
      html.push('<li><a href="');
      html.push(license.url);
      html.push('">');
      html.push(name);
      html.push(' by ');
      html.push(license.publisher);
      html.push('</a></li>');
    }
    html.push('</ul>');
    dst.push({ message: html.join('') });
  }
  return dst;
}
