export default {
  data() {
    return {
      show: false
    };
  },
  created() {
    this.$mount();
    document.body.appendChild(this.$el);
    this.show = true;
  },
  methods: {
    close() {
      this.show = false;
      setTimeout(() => {
        if (document.body.contains(this.$el))
          document.body.removeChild(this.$el);
        this.$destroy();
      }, 200);
    },
    keydown(event) {
      if (event.keyCode == 27) {
        this.hideModal();
      }
    },
    focusin(event) {
      if (!(event.target === this.$el || this.$el.contains(event.target))) {
        event.preventDefault();
        this.$el.focus();
      }
    }
  },
  mounted() {
    document.activeElement.blur();
    document.addEventListener('focusin', this.focusin);
    document.addEventListener('keydown', this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
    document.removeEventListener('focusin', this.focusin);
  }
};
