'use strict';

//import
import Store from '@/store/index.js';

/**
 * @returns {String} アクセストークン
 */
function getToken() {
  return Store.state.auth.token;
}

async function verify() {
  await Store.dispatch('auth/verify');
}

function isLogin() {
  return Store.state.login;
}

/**
 * Default Function
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
async function beforeEach(to, from, next) {
  {
    if (Store.state.debug) {
      await verify();

      if (!getToken) console.error('アクセストークンなし' + getToken());

      next();
      return;
    }

    if (!to.matched.some(page => page.meta.public)) {
      if (!isLogin()) {
        if (getToken()) {
          await verify();
        }
        if (!getToken()) {
          next({ name: 'Login', params: { next: to.path } });
          return;
        }
      }
    }

    next();
  }
}

export default beforeEach;
