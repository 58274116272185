import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import { API_SERVER_URL as Url } from './defines';
console.log('API Server: ' + Url);

Vue.config.productionTip = false;

Vue.prototype.$titles = {
  main: 'えひめっこ情報リテラシーアプリ',
  literacy: 'ジャッジメントルーム',
  ability: 'タイムレースルーム'
};

import Loading from './components/Loading';
let loading = null;

Vue.prototype.$loading = function(timeout, onCancel) {
  if (loading) return true;

  new Promise(() => {
    const VM = Vue.extend(Loading);
    loading = new VM({
      parent: this,
      propsData: { timeout, onCancel }
    });
  }).catch(err => {
    console.error(err);
    throw err;
  });
};

Vue.prototype.$unloading = function() {
  if (loading) loading.close();
  loading = null;
};

Vue.prototype.$tryLoading = function(callback) {
  if (this.$loading()) return;
  try {
    return callback();
  } finally {
    this.$unloading();
  }
};

Vue.prototype.$tryLoadingAsync = async function(callback) {
  if (this.$loading()) return;
  try {
    return await callback();
  } finally {
    this.$unloading();
  }
};

new Vue({
  router,
  store,
  //vuetify,
  render: h => h(App)
}).$mount('#app');
