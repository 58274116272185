<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    document.head.appendChild(script);
  }
};
</script>
<style src="@/assets/icons/icons.css"></style>
<style src="@/views/ability/css/ability-common.css"></style>
<style src="@/views/literacy/css/chat.css"></style>
<style src="@/views/literacy/css/common-layout.css"></style>
<style src="@/views/literacy/css/common.css"></style>
<style src="@/views/literacy/css/front-page.css"></style>
<style src="@/views/literacy/css/story.css"></style>
<style src="@/views/literacy/css/coping.css"></style>
<style src="@/views/literacy/css/test.css"></style>
<style>
/*
html,
body {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
#root-html {
  overflow: auto;
}
*/

nav.btn-auto-read.disabled {
  display: none;
}
</style>
