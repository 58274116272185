import * as utils from './service/utils';

export const API_SERVER_URL = '/api';

export const NO_PASSWORD_VALIDATION = true;

//ToDo: impl. ruby
export const ERROR_MESSAGE = {
  UserNotFound: 'ユーザーIDが間違っています。',
  UserDisabled: 'ユーザーが無効です。',
  PasswordDoesNotMatch: 'パスワードが正しくありません。',
  UnknownError: 'UnknownError',
  PatternNotMatch: '使用できない文字が含まれています。',
  Required: '必須項目です。'
};

//ToDo: fix to obtain consistency among client / server password check logics
export const RULES = {
  Required: value => !!value || ERROR_MESSAGE.Required,
  Min8: v => v.length >= 8 || 'Min 8 characters',
  EmailMatch: () => "The email and password you entered don't match",
  PasswordMatch: v => utils.isPassword(v) || ERROR_MESSAGE.PatternNotMatch
};
