const REGEX_RUBY = /\[(.*?):(.*?)\]/gm;
const REGEX_ANNOTATION = /\((.*?):(.*?)\)/gm;
const REGEX_RUBY_HTML = /<ruby.*?<rt>(.*?)<\/rt>.*?\/ruby>/gm;

const formatRuby = new Formatter(
  REGEX_RUBY, // [振り仮名:フリガナ]
  m => `<ruby><rb>${m[1]}</rb><rt>${m[2]}</rt></ruby>`
);

const formatAnnotation = new Formatter(
  REGEX_ANNOTATION, // (用語:注釈)
  m => `<dl class="annotation"><dt>${m[1]}</dt><dd>${m[2]}</dd></dl>`
);

const formatNtoP = new Formatter(
  /^(.+)$/gm, // 改行(\n)をPへ
  m => `<p>${m[1]}</p>`
);

const formatSpeechRuby = new Formatter(
  REGEX_RUBY, // [振り仮名:フリガナ]
  m => `${m[2]}`
);
const formatSpeechRubyHtml = new Formatter(
  REGEX_RUBY_HTML, // [振り仮名:フリガナ]
  m => `${m[1]}`
);

const formatSpeechAnnotation = new Formatter(
  REGEX_ANNOTATION, // (用語:注釈)
  m => `${m[1]}`
);

const formatMapper = new Formatter(
  /{{(.*?)}}/gm, // standard object mapping
  (m, o) => o[m[1]]
);
const formatSpeechMapperTag = new Formatter(
  /{{f(.*?)}}/gm, // (用語:注釈)
  m => `{{s${m[1]}}}`
);

const formatAll = new MultiFormatter([
  formatRuby,
  formatAnnotation,
  formatNtoP
]);
const formatAllasLine = new MultiFormatter([formatRuby, formatAnnotation]);

const formatAsSpeechText = new MultiFormatter([
  formatSpeechRuby,
  formatSpeechRubyHtml,
  formatSpeechAnnotation
]);

function Formatter(re, format) {
  this._re = re;
  this._format = format;
}
Formatter.prototype.format = function(src, obj) {
  if (src === undefined || src === null) {
    return undefined;
  }
  const dst = [];
  let m;
  this._re.lastIndex = 0;
  let start = 0;
  while ((m = this._re.exec(src)) !== null) {
    dst.push(src.substring(start, m.index));
    dst.push(this._format(m, obj));
    start = this._re.lastIndex;
  }
  dst.push(src.substring(start, src.length));
  return dst.join('');
};

function MultiFormatter(formatters) {
  this.formatters = formatters;
}
MultiFormatter.prototype.format = function(src) {
  let dst = src;
  for (let i = 0; i < this.formatters.length; i++) {
    dst = this.formatters[i].format(dst);
  }
  return dst;
};

export {
  Formatter,
  MultiFormatter,
  formatRuby,
  formatAnnotation,
  formatNtoP,
  formatAll,
  formatAllasLine,
  formatAsSpeechText,
  formatMapper,
  formatSpeechMapperTag
};
