<template>
  <div id="global">
    <div id="global-top">
      <div id="global-top-wrapper">
        <div id="global-top-title"></div>
        <div id="global-top-menu-wrapper">
          <div id="global-literacy">
            <a href="/literacy">
              <img src="@/assets/img/top-login/btn-literacy.png" />
            </a>
          </div>
          <div id="global-ability">
            <a href="/ability">
              <img src="@/assets/img/top-login/btn-ability.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <footer id="footer">
      <a href="#" @click.prevent="onClickAbout">©えひめリテラシー向上事業</a>
      | <a href="/policy">プライバシーポリシー</a>
    </footer>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue';
import DialogScrollText from '@/components/DialogScrollText.vue';
import dialogs from '@/mixins/dialogs';
import { license2ScrollTextData } from '@/service/license';

export default {
  name: 'Top',
  mixins: [dialogs],
  props: {},
  data() {
    return {};
  },
  methods: {
    async onClickAbout() {
      let texts = await license2ScrollTextData(
        await this.$store.dispatch('api/getLicense')
      );
      await this.$confirm({
        component: DialogScrollText,
        props: {
          texts: texts
        }
      });
    }
  }
};
</script>
<style>
html,
body,
#global {
  width: 100%;
  height: 100%;
}
#global {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

#global #footer {
  height: 2em;
  background-color: #fba348;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#global #footer a {
  color: #fff;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 1em;
}

#global-top {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    -45deg,
    #fdf5cf 25%,
    #fff0b3 25%,
    #fff0b3 50%,
    #fdf5cf 50%,
    #fdf5cf 75%,
    #fff0b3 75%,
    #fff0b3
  );
  background-size: 18px 18px;
  background-repeat: repeat;
  flex: 1;
}

#global-top-wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
}

#global-top-title {
  background: url('../assets/img/top-login/logo-alltop.png');
  background-size: contain;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  flex: 2;
}
#global-top-menu-wrapper {
  display: flex;
  flex: 1;
}
#global-top-menu-wrapper > div {
  margin: 1em 1em 0 1em;
  flex: 1;
}

#global-top img {
  object-fit: contain;
  max-width: 100%;
}

#global-literacy {
  text-align: right;
}

#global-ability {
  text-align: left;
}

@media screen and (max-aspect-ratio: 2/3) {
  #global-top-title {
    flex: 1;
  }
  #global-top-menu-wrapper {
    flex-direction: column;
    flex: 1.5;
  }
  #global-top-menu-wrapper > div {
    flex: 0;
  }
}
</style>
