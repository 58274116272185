<template>
  <Modal :class="'dialog-' + type" @click="close">
    <div class="dialog-wrapper">
      <div v-if="title" class="dialog-header" v-html="ftitle"></div>

      <div v-if="image" class="dialog-image">
        <img :src="image" />
      </div>
      <div v-if="message" class="dialog-message-wrapper">
        <div
          class="dialog-message"
          v-html="formatMessage ? fmessage : message"
        ></div>
      </div>

      <div class="dialog-options">
        <ul>
          <li
            v-for="(option, id) in options"
            :key="option + id"
            :class="['dialog-option', option.style]"
            @click="select(option)"
          >
            <span v-html="option.text"></span>
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import { formatAll, formatAllasLine } from '../service/formatter.js';
import modal from '../mixins/modal';
export default {
  name: 'Dialog',
  components: {
    Modal: () => import('./Modal.vue')
  },
  mixins: [modal],
  props: {
    type: { type: String, default: 'horizontal' },
    title: { type: String },
    message: { type: String },
    formatMessage: { type: Boolean, default: true },
    image: String,
    options: { type: Array, default: () => [{ text: 'OK', value: true }] },
    onClose: { type: Function, required: true }
  },
  data() {
    return {
      visible: false,
      result: false
    };
  },
  computed: {
    ftitle() {
      return formatAllasLine.format(this.title);
    },
    fmessage() {
      return formatAll.format(this.message);
    }
  },
  methods: {
    select(option) {
      this.onClose(option.value);
      this.close();
    }
  }
};
</script>

<style scoped>
.dialog-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  min-width: 80vw;
}

@media screen and (min-aspect-ratio: 1/1) {
  .dialog-wrapper {
    min-width: 50vw;
  }
}

.dialog-header {
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding: 0.5em;
  font-size: 150%;
}

.dialog-image {
  flex: 1;
  background-size: contain;
  background-position: center;
}

.dialog-image img {
  width: 100%;
  max-height: 20vh;
  object-fit: contain;
}

.dialog-message-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.dialog-message {
  overflow: auto;
  max-height: 50vh;
  padding: 2em 1em;
  font-size: 150%;
}

.dialog-options ul {
  display: flex;
  margin: 0;
  color: #fff;
  user-select: none;
}

.dialog-option span {
  font-size: 150%;
  text-align: center;
}
.dialog-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  background-color: #fca249;
}

.dialog-option:hover {
  font-weight: bold;
  opacity: 0.8;
}

.dialog-option:active {
  box-shadow: inset 0.1em 0.1em 0.3em #0004;
  opacity: 0.6;
}

.dialog-option.highlight {
  background-color: #db4807;
}

.dialog-horizontal .dialog-options ul {
  flex-direction: row;
}

.dialog-horizontal .dialog-option:not(:first-child) {
  margin-left: 1px;
}

.dialog-horizontal .dialog-option:first-child {
  border-bottom-left-radius: 1em;
}

.dialog-horizontal .dialog-option:last-child {
  border-bottom-right-radius: 1em;
}

.dialog-vertical .dialog-options ul {
  flex-direction: column;
}

.dialog-vertical .dialog-option:not(:first-child) {
  margin-top: 1px;
}

.dialog-vertical .dialog-option:last-child {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.dialog-wrapper .small {
  font-size: 50%;
}
.dialog-wrapper .large {
  font-size: 150%;
}
</style>
