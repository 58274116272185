'use strict';

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import api from './modules/api';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login: false,
    debug: false
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.management.drawer = payload;
    },
    login(state) {
      state.login = true;
    },
    logout(state) {
      state.login = false;
      state.auth.token = null;
    }
  },
  actions: {},
  modules: { auth, api },
  plugins: [
    createPersistedState({
      key: 'EhimeNetLiteracy',
      paths: ['auth.token'],
      storage: window.localStorage
    })
  ]
});
