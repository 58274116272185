'use strict';

import { API_SERVER_URL as BaseURL } from '@/defines';
import axios from 'axios';

import Store from '@/store/index.js';

function getToken() {
  return Store.state.auth.token;
}

export const Methods = {
  User: 'user',
  Auth: 'auth',
  Image: 'image',
  LiteracyRoom: 'literacy_room',
  LiteracyContent: 'literacy_content'
};

function createHttpClient() {
  return axios.create({
    baseURL: BaseURL
  });
}

export async function insertImage(file) {
  var client = createHttpClient();

  var option = { headers: { 'content-type': 'multipart/form-data' } };

  var formData = new FormData();
  formData.append('file', file);

  var result = await client.post('image', formData, option).catch(e => {
    console.error(e.response);
    throw e;
  });
  console.log(result);
  return result.data;
}

export async function updateImage(_id, file) {
  var client = createHttpClient();

  var option = { headers: { 'content-type': 'multipart/form-data' } };

  var formData = new FormData();
  formData.append('file', file);

  var url = 'image/' + _id;

  var result = await client.put(url, formData, option).catch(e => {
    console.error(e.response);
    throw e;
  });
  console.log(result);
  return result.data;
}

export async function selectImage(_id) {
  var client = createHttpClient();

  var url = 'image/' + _id;

  var result = await client.get(url).catch(e => {
    console.error(e.response);
    throw e;
  });
  console.log(result);
  return result;
}

export async function selectImageSync(_id) {
  var client = createHttpClient();

  var url = 'image/' + _id;

  client
    .get(url)
    .then(result => {
      console.log(result);
      return result;
    })
    .catch(e => {
      console.error(e.response);
      throw e;
    });
}

export async function deleteImage(_id) {
  var client = createHttpClient();

  var url = 'image/' + _id;

  var result = await client.delete(url).catch(e => {
    console.error(e.response);
    throw e;
  });
  console.log(result);
  return result.data;
}

export async function getImage(_id) {
  var client = createHttpClient();
  var option = {
    responseType: 'blob',
    headers: {
      'Content-Type': 'image/png'
    }
  };
  var url = 'image/' + _id + '?token=' + getToken();
  var result = await client.get(url, option).catch(e => {
    console.error(e.response);
    throw e;
  });
  console.log(result);
  return result.data;
}

export function getImageStream(_id, onRead) {
  const url = BaseURL + '/image/' + _id + '?token=' + getToken();
  return fetch(url, {
    headers: {
      'Content-Type': 'image/png'
    }
  }).then(response => {
    if (!response.ok) {
      throw new Error('bad response');
    }

    if (!response.body) {
      throw new Error('no response body');
    }

    const encoding = response.headers.get('content-encoding');
    let length = response.headers.get(
      encoding ? 'x-file-size' : 'content-length'
    );
    if (length === null) {
      throw new Error('content length');
    }

    length = parseInt(length);
    let loaded = 0;

    return new Response(
      new ReadableStream({
        start(controller) {
          const reader = response.body.getReader();
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              loaded += value.byteLength;
              onRead(loaded, length, url);
              controller.enqueue(value);
              return pump();
            });
          }
        }
      })
    );
  });
}

/**
 * Get Method
 * @param {String} path
 * @param {String|String[]} params
 * @param {Object} query
 */
export async function get(path, params, query = {}) {
  let client = createHttpClient();

  query.token = getToken();
  let url = getUrl(path, params, query);
  try {
    var result = await client.get(url);
  } catch (err) {
    console.error(err.response);
    throw err;
  }
  console.log(result);
  return result;
}

/**
 * POST Method
 * @param {String} path
 * @param {Object} data
 * @param {String|String[]} params
 */
export async function post(path, data = {}, params) {
  let client = createHttpClient();

  data.token = getToken();
  let url = getUrl(path, params);

  try {
    var result = await client.post(url, data);
  } catch (err) {
    console.error(err.response);
    throw err;
  }
  console.log(result);
  return result;
}

/**
 * PUT Method
 * @param {String} path
 * @param {Object} data
 * @param {String|String[]} params
 */
export async function put(path, data = {}, params) {
  let client = createHttpClient();

  data.token = getToken();
  let url = getUrl(path, params);

  try {
    var result = await client.put(url, data);
  } catch (err) {
    console.error(err.response);
    throw err;
  }
  console.log(result);
  return result;
}

/**
 * Get Method
 * @param {String} path
 * @param {String|String[]} params
 * @param {Object} query
 */
export async function del(path, params, query = {}) {
  let client = createHttpClient();

  query.token = getToken();
  let url = getUrl(path, params, query);

  try {
    var result = await client.delete(url);
  } catch (err) {
    console.error(err.response);
    throw err;
  }
  console.log(result);
  return result;
}

/**
 *
 * @param {String} baseUrl
 * @param {String|String[]} params
 * @param {Object} query
 */
function getUrl(baseUrl, params, query) {
  let url = baseUrl;

  if (url.slice(-1) !== '/') url += '/';

  if (params) {
    if (typeof params === 'string') {
      url += params;
    } else {
      url += params.join('/');
    }
  }

  if (query) {
    let querys = [];
    for (let key in query) querys.push(key + '=' + query[key]);
    url += '?' + querys.join('&');
  }

  return url;
}
