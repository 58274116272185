<template>
  <Modal class="dialog-scroll-text" @click="close">
    <div class="dialog-scroll-text-wrapper" @click="close">
      <div v-for="(item, i) in texts" :key="i" class="dialog-scroll-text-items">
        <div v-if="item.image" class="dialog-scroll-text-image">
          <img :src="item.image" />
        </div>
        <div class="message" v-html="item.message"></div>
      </div>
    </div>
    <div class="dialog-options">
      <ul>
        <li class="dialog-option" @click="close">
          <span>閉じる</span>
        </li>
      </ul>
    </div>
  </Modal>
</template>

<script>
import modal from '@/mixins/modal';
export default {
  name: 'DialogScrollText',
  components: {
    Modal: () => import('@/components/Modal.vue')
  },
  mixins: [modal],
  props: {
    texts: {
      type: Array,
      required: true
    },
    onClose: { type: Function, required: true }
  },
  data() {
    return {
      visible: false,
      result: false
    };
  },
  methods: {}
};
</script>

<style scoped>
.dialog-scroll-text-wrapper {
  margin: 1em;
  overflow: auto;
  max-height: 90vh;
  max-width: 760px;
}
.dialog-scroll-text-items {
  padding: 0.25em;
  margin: 0.5em 0.25em;
}
.dialog-scroll-text-items .message {
  margin-left: 0.5em;
  line-height: 1.5em;
  font-size: 150%;
}

.dialog-scroll-text-items .message h1 {
  font-size: 150%;
  font-weight: bold;
}
.dialog-scroll-text-items .message h2 {
  font-size: 120%;
  font-weight: bold;
}
.dialog-scroll-text-items .message li {
  margin-left: 2em;
  list-style: circle;
}
.dialog-scroll-text-items .message a {
  color: #444;
}
.dialog-scroll-text-items .message a:hover {
  color: #fba348;
}

::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}
::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 0.5em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 1em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #444;
}

.dialog-scroll-text-image {
  flex: 1;
  background-size: contain;
  background-position: center;
  margin-bottom: 0.5em;
}

.dialog-scroll-text-image img {
  width: 100%;
  height: auto;
  max-height: 50vh;
  object-fit: contain;
}

.dialog-options ul {
  display: flex;
  margin: 0;
  color: #fff;
  user-select: none;
  flex-direction: column;
}

.dialog-option span {
  font-size: 150%;
}
.dialog-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  background-color: #fca249;
}

.dialog-option:hover {
  font-weight: bold;
  opacity: 0.8;
}

.dialog-option:active {
  box-shadow: inset 0.1em 0.1em 0.3em #0004;
  opacity: 0.6;
}

.dialog-option.highlight {
  background-color: #db4807;
}

.dialog-option:not(:first-child) {
  margin-top: 1px;
}

.dialog-option:last-child {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
</style>
