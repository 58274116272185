<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="!!onCancel"
      :on-cancel="cancel"
      :is-full-page="fullPage"
    ></loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    Loading
  },
  props: {
    timeout: { type: Number, default: 0 },
    onCancel: { type: Function, default: null }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true
    };
  },
  created() {
    this.$mount();
    document.body.appendChild(this.$el);

    this.isLoading = true;

    if (this.timeout)
      setTimeout(() => {
        this.isLoading = false;
      }, this.timeout);
  },
  methods: {
    cancel() {
      if (this.onCancel) this.onCancel();
      this.close();
    },
    close() {
      this.isLoading = false;
      setTimeout(() => {
        if (document.body.contains(this.$el))
          document.body.removeChild(this.$el);
        this.$destroy();
      }, 200);
    }
  }
};
</script>

<style></style>
