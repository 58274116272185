'use strict';

import Vue from 'vue';

export default {
  methods: {
    async $confirm(payload) {
      return new Promise(resolve => {
        const VM = Vue.extend(payload.component);
        payload.props.onClose = resolve;
        new VM({
          parent: this,
          propsData: payload.props
        });
      }).catch(err => {
        throw err;
      });
    }
  }
};
